<template>
  <c-x-features type="measure" class="Measure">
    <c-x-traits-list
      :traits="traits"
      type="measure"
    />
  </c-x-features>
</template>

<script>
import CXTraitsList from '@/components/cx/CXTraitsList'
import CXFeatures from '@/router/layouts/CXFeatures'

/**
 * @module Measure
 */
export default {
  name: 'Measure',
  components: { CXTraitsList, CXFeatures },
  data () {
    return {
      traits: [
        'coverage', 'facts', 'ROI', 'KPI', 'clippings', 'reports'
      ]
    }
  }
}
</script>
