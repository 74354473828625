<template>
  <div class="CXTrait card">
    <div class="card-content">
      <div class="columns">
        <div class="column is-4 p-l-m p-r-m has-text-centered">
          <img :src="image" :alt="title">
        </div>
        <div class="column is-4 is-flex is-column is-aligned-center">
          <heading color="black" size="4" gapless>
            {{ title }}
          </heading>
          <div>
            {{ content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module CXTrait
 */
export default {
  name: 'CXTrait',
  props: {
    title: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  }
}
</script>
