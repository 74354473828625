<template>
  <div class="CXTraitsList p-v-l">
    <c-x-trait
      v-for="(trait, index) in traits"
      :key="index"
      :title="$t(`pages.cx.${type}.traits.${trait}.title`)"
      :content="$t(`pages.cx.${type}.traits.${trait}.content`)"
      :image="$asset(`/images/cx/${type}/traits/${trait}.png`)"
      class="m-b-m"
    />
  </div>
</template>

<script>
import CXTrait from '@/components/cx/CXTrait'

/**
 * @module CXTraitsList
 */
export default {
  name: 'CXTraitsList',
  components: { CXTrait },
  props: {
    /**
     * @type String
     * Used as a feature domain
     */
    type: {
      type: String,
      required: true
    },
    /**
     * @type Array
     * Loops it over to build the feature set
     */
    traits: {
      type: Array,
      required: true
    }
  }
}
</script>
